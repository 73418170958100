<template>
  <v-container>
    <v-form>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" class="pt-10">
              <span><b>แบบแสดงรายการคำนวณภาษีที่ดินและสิ่งปลูกสร้าง</b></span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="pt-2">
              <span>ชื่อ - สกุล</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.fullName }}
            </v-col>
            <v-col cols="2" class="pt-2">
              <span>เลขบัตรประจำตัวประชาชน</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.idCardNo }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>บ้านเลขที่</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.houseNo }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>หมู่</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.moo }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12"
          ><v-row>
            <v-col cols="12" class="pt-10">
              <span><b>คำนวณราคาประเมินทุนทรัพย์ของที่ดิน</b></span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="pt-2">
              <span>ประเภทที่ดิน</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.landType }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>เลขที่</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.documentNo }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>ลักษณะการทำประโยชน์</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.profitType }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>จำนวนเนื้อที่ดิน </span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{
                `${data.areaRai} ไร่ ` +
                ` ${data.areaNgan} งาน ` +
                ` ${data.areaWah} วา`
              }}
            </v-col>
            <v-col cols="2" class="pt-2">
              <span>คำนวณเป็น ตร.ว.</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.squareWah }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>ราคาประเมินต่อ ตร.ว. (บาท)</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.squareWahPrice }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>ราคาประเมินของที่ดิน (บาท)</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.landPrice }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="pt-10">
          <span><b>คำนวณราคาประเมินทุนทรัพย์ของสิ่งปลูกสร้าง</b></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="pt-2">
          <span>ประเภทของสิ่งปลูกสร้าง</span>
        </v-col>
        <v-col cols="4" class="pt-2">
          {{ data.buildingName }}
        </v-col>
        <v-col cols="2" class="pt-2">
          <span>ขนาดพื้นที่สิ่งปลูกสร้าง (ตร.ม.)</span>
        </v-col>
        <v-col cols="4" class="pt-2">
          {{ data.buildingType }}
        </v-col>
        <v-col cols="2" class="pt-2">
          <span>ราคาประเมินต่อ ตร.ม. (บาท)</span>
        </v-col>
        <v-col cols="4" class="pt-2">
          {{ data.buildingAreaPrice }}
        </v-col>

        <v-col cols="2" class="pt-2">
          <span>รวมราคาประเมินสิ่งปลูกสร้าง (บาท)</span>
        </v-col>
        <v-col cols="4" class="pt-2">
          {{ data.buildingPrice }}
        </v-col>
        <v-col cols="2" class="pt-2">
          <span>อายุโรงเรือน (ปี)</span>
        </v-col>
        <v-col cols="4" class="pt-2">
          {{ data.buildingAge }}
        </v-col>
        <v-col cols="2" class="pt-2">
          <span>คิดค่าเสื่อม (บาท)</span>
        </v-col>
        <v-col cols="1" class="pt-2">
          {{ data.depreciation }}
        </v-col>
        <v-col cols="2" class="pt-2">
          <span>ราคาประเมินสิ่งปลูกสร้างหลังหักค่าเสื่อม (บาท)</span>
        </v-col>
        <v-col cols="1" class="pt-2">
          {{ data.buildingPriceMinusDepreciation }}
        </v-col>

        <v-col cols="12"
          ><v-row>
            <v-col cols="12" class="pt-10">
              <span><b>หมวด ก</b></span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="pt-2">
              <span>รวมราคาประเมินของที่ดินและสิ่งปลูกสร้าง(บาท)</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.priceLandPlusBuilding }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span
                >ราคาประเมินของที่ดินและสิ่งปลูกสร้างตามสัดส่วนการใช้ประโยชน์</span
              >
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.priceLandPlusBuildingPerProfit }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>หักมูลค่าฐานภาษีที่ได้รับยกเว้น (บาท)</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.priceExceptTax }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>คงเหลือราคาประเมินทุนทรัพย์ที่ต้องชำระ (บาท)</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.priceTax }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>อัตราภาษี(ร้อยละ)</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.taxPercent }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>จำนวนภาษี่ที่ต้องชำระ(บาท)</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.tax }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>เกษตร</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.landProfitAgri }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>ที่อยู่อาศัย</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.landProfitHome }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>จำนวนภาษี่ที่ต้องชำระ(บาท)</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.tax }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>อื่น ๆ</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.landProfitOther }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>ว่างเปล่า/ไม่ทำประโยชน์</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.landProfitBlank }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>ใช้ประโยชน์หลายประเภท</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.landProfitMany }}
            </v-col>
            <v-col cols="5"></v-col>
            <v-col cols="2" class="py-4">
              <v-btn color="#744D9c" style="color: white" @click="backpds7()"
                >กลับ</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      list: [],
      count: 0,
      checkuser: [],
      data: [],
    };
  },
    methods: {
   backpds7() {
   this.$router.push("/pds7");
   },
    
  },
  created() {
    this.checkuser = JSON.parse(Decode.decode(localStorage.getItem("user")));
    this.data = JSON.parse(Decode.decode(localStorage.getItem("data")));
    console.log("data", this.data); // this.pds1data คือค่าที่ส่งมาจาก แว่นขยาย
    // this.getviewallpds1();
  },
};
</script>
<style scoped>
.f-right {
  float: right;
}
</style>